/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//Ngx-Table
@import 'node_modules/@swimlane/ngx-datatable/index.css';
// @import '@swimlane/ngx-datatable/themes/bootstrap.scss';
@import 'node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import 'node_modules/@swimlane/ngx-datatable/themes/bootstrap.scss';
@import 'node_modules/@swimlane/ngx-datatable/themes/dark.scss';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';
//Toastr
@import 'ngx-toastr/toastr';
//quill-editor
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";

@import "/node_modules/flag-icons/css/flag-icons.min.css";

:host {
  --background: var(--ion-background-color) !important;
}

.native-input.sc-ion-input-md {
  margin-left: 10px
}

.popover-wide {
  .alert-radio-label.sc-ion-alert-md {
    color: var(--ion-text-color) //-color-secondary-contrast)
  }

  [aria-checked=true].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
    color: var(--ion-text-color) //var(--ion-color-medium); //-color-secondary-contrast)
  }

  [aria-checked=true].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
    border-color: var(--ion-color-medium); //var(--ion-text-color); //black;
    background-color: var(--ion-color-medium); //var(--ion-text-color); //black
  }
}

.native-textarea.sc-ion-textarea-md {
  margin-left: 10px;
  width: auto
}

.sc-ion-textarea-md-h {
  --highlight-color-focused: none;
}

.sc-ion-input-md-h {
  --highlight-color-focused: none
}

// global css for ionic elements that are using in the application
ion-card {
  --background: var(--ion-color-secondary);
  --color: var(--ion-color-secondary-contrast);
}

ion-card.detailsView {
  --background: var(--ion-color-adminDetailsViewBg);
  --color: var(--ion-color-adminDetailsViewBg-contrast);
  box-shadow: none;
}

ion-item {
  --background: transparent !important;
  --highlight-color-invalid: var(--ion-color-primary-contrast); // invalid underline color
  --highlight-color-valid: var(--ion-color-primary-contrast);
  --ion-color-shade: var(--ion-color-primary-contrast) !important;
}

ion-item.item-input {
  --background: transparent !important;
  --highlight-color-invalid: var(--ion-color-warning); // invalid underline color
  --highlight-color-valid: var(--ion-color-primary-contrast);
  --ion-color-shade: var(--ion-color-primary-contrast) !important;
}

ion-item.item-select {
  --ion-background-color: transparent !important;
  --highlight-color-invalid: transparent !important; // invalid underline color
  --highlight-color-valid: transparent !important;
}

ion-item.item-has-focus>ion-label {
  color: var(--ion-color-warning) !important;
}

ion-menu {
  --width: 350px;

  ion-content {
    --ion-background-color: var(--ion-color-sidemenu) !important;
    --ion-text-color: var(--ion-color-sidemenu-contrast) !important;
    --ion-icon: var(--ion-color-sidemenu-contrast) !important;
  }

  ion-footer {
    background-color: var(--ion-color-sidemenu) !important;
    --ion-text-color: var(--ion-color-sidemenu-contrast) !important;
  }

  ion-icon {
    color: var(--ion-color-sidemenu-contrast) !important;
  }

  ion-header {
    ion-icon {
      color: var(--ion-color-secondary-contrast) !important;
    }
  }
}

ion-label {
  &.required:after {
    content: " *";
    color: var(--ion-color-danger);
  }
}

div,
p {
  &.required:after {
    content: " *";
    color: var(--ion-color-danger);
  }
}

ion-header {
  &.custom {
    &--sticky {
      z-index: 11;
      position: sticky;
      top: 0;
    }
  }
}

ion-searchbar {
  --background: var(--ion-color-searchbar) !important;
  --color: var(--ion-color-searchbar-contrast) !important;
  --placeholder-color: var(--ion-color-searchbar-contrast) !important;
  --icon-color: var(--ion-color-searchbar-contrast) !important;
  --clear-button-color: var(--ion-color-searchbar-contrast) !important;
}

ion-spinner {
  --color: var(--ion-color-loadingSpinner) !important;
}

ion-alert {
  ion-content {
    background: var(--ion-color-alert) !important;
  }

  --ion-text-color: var(--ion-color-alert-contrast) !important;
  --ion-background-color: var(--ion-color-alert);

  .alert-message {
    color: var(--ion-color-alert-contrast) !important;
  }

  .alert-button-inner {
    --ion-text-color: var(--ion-color-alert-contrast) !important;
    color: var(--ion-color-alert-contrast) !important;
  }
}

ion-select-popover {
  --ion-background-color: var(--ion-color-alert) !important;
  --ion-text-color: var(--ion-color-alert-contrast) !important;
  --ion-icon-color: var(--ion-color-alert-contrast) !important;
}

ion-list {
  --ion-background-color: var(--ion-color-alert) !important;
  --ion-text-color: var(--ion-color-alert-contrast) !important;

  ion-icon {
    color: var(--ion-color-alert-contrast) !important;
  }
}

ion-list-header {
  --ion-background-color: var(--ion-color-primary) !important;
  --ion-text-color: var(--ion-color-primary-contrast) !important;

  ion-icon {
    color: var(--ion-color-primary-contrast) !important;
  }
}

.ion-page {
  display: block;
  overflow-y: overlay;
}

@media only screen and (min-height: 800px) {
  .custom-modal {
    --min-height: 800px;
  }
}

@media only screen and (max-height: 800px) {
  .custom-modal {
    --height: 100%;
  }
}


.modal-open {
  overflow-y: hidden !important;
}

.spinner {
  height: 8vh;
  width: 8vh;
  left: 45%;
  top: 45%;
  opacity: 0.7;
}

.searchbar-input {
  border-radius: 8px !important;
  box-shadow: none !important;
}

// Apply different card-size for different media types/devices
@media only screen and (max-width: 1100px) {
  #desktop {
    margin-top: 1em;
    overflow-y: hidden;
  }

  #title {
    margin-left: 0.5em;
  }

  #dashboard {
    overflow-y: hidden;
  }

}

@media only screen and (min-width: 1101px) {
  .grid {
    margin-inline-start: 3%;
  }

  #desktop {
    margin-top: 2em;
    margin-left: 20%;
    margin-right: 20%;
    padding: 15px;
    min-width: 520px;

  }

  .desktopMargin {
    margin-top: 2em;
  }

  #dashboard {
    margin-left: 25%;
    margin-right: 25%;
  }

}

@media only screen and (min-width: 2100px) {
  .grid {
    margin-inline-start: 5%;
  }

  #desktop {
    margin-top: 2em;
    margin-left: 30%;
    margin-right: 30%;
  }

  #dashboard {
    margin-left: 30%;
    margin-right: 30%;
  }

}

// Apply the different styles css for ngx-datatable in the application
.ngx-datatable.bootstrap:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background: var(--ion-color-secondary);
}

.ngx-datatable .datatable-footer {
  .page-count {
    color: var(--ion-color-secondary-contrast);
  }

  .datatable-pager {
    li {
      &.disabled a {
        color: var(--ion-color-secondary-contrast);
      }

      &.active a {
        color: var(--ion-color-secondary-contrast);
      }
    }

    .pager {
      li {
        a {
          color: var(--ion-color-secondary-contrast);
        }
      }
    }

    .datatable-icon-left,
    .datatable-icon-skip,
    .datatable-icon-right,
    .datatable-icon-prev {
      color: var(--ion-color-secondary-contrast);
    }
  }

  overflow: hidden;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none
}

.ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  padding: 0;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: bottom;
}

.datatable-footer-inner {
  color: var(--ion-color-primary-contrast);
  display: flex;
  justify-content: space-between;
}

.ngx-datatable {
  background-color: transparent !important;
  box-shadow: none !important;

  ::ng-deep {
    .datatable-scroll {
      width: 100% !important;

      datatable-body-row {
        width: 100% !important;

        &:hover,
        &:focus {
          background-color: transparent !important;

          .datatable-row-group {
            &:hover {
              background-color: transparent !important;
            }
          }
        }

        datatable-body-cell,
        datatable-row-group {
          transition: none !important;
          width: 100%;
          padding: 0 !important;
        }
      }
    }
  }
}

.ngx-datatable {
  & .datatable-footer {
    overflow: hidden !important;
  }
}

// Apply styles for groups/users/reports cards
.selection {
  display: flex;
  margin-top: 2em;
  justify-content: center;
  gap: 40px;

  .checkbox {
    display: flex;
    gap: 5px;
  }
}

.search {
  margin-bottom: 5px;
}

.email {
  color: var(--ion-color-primary-contrast);
  display: flex;
  align-items: center;

  ion-icon {
    margin-right: 5px;
  }
}

.logo {
  height: 90px;
  width: 90px;
  margin: auto;
  display: block;
}

.card {
  background-color: var(--ion-color-secondary);
  border-radius: 10px;

  .activation-icon {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;

    .status {
      color: var(--ion-color-secondary-contrast);
      display: flex;
      flex-direction: column;
    }
  }

  .card-header {

    .menu-icon {
      float: right;
      color: var(--ion-color-secondary-contrast);

      &:hover {
        cursor: pointer;
      }
    }

    .card-title {
      color: var(--ion-color-secondary-contrast);
    }

    .email {
      color: var(--ion-color-secondary-contrast);
      display: flex;
      align-items: center;

      ion-icon {
        margin-right: 5px;
      }
    }

    .more-info {
      color: var(--ion-color-secondary-contrast);
      display: flex;
      align-items: center;

      ion-icon {
        margin-right: 5px;
      }
    }

    .report {
      color: var(--ion-color-secondary-contrast);
      display: flex;
      align-items: center;

      ion-icon {
        margin-right: 5px;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.group-info {
  border-bottom: 1px solid var(--ion-color-secondary);
  margin: 0 10px 20px 10px;
  padding-bottom: 20px;
  color: var(--ion-color-secondary-contrast);

  h1 {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .more-info {
    color: var(--ion-color-secondary-contrast);
    display: flex;
    align-items: center;

    ion-icon {
      margin-right: 5px;
    }
  }

  .card {
    margin: 0 0 20px 0;
  }
}

.report-list {
  display: flex;
  justify-content: center;

  .report {
    display: flex;
    justify-content: center;
    flex-flow: wrap;
    max-width: 1500px;
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

.interactive {
  &:hover {
    outline: 2px var(--ion-color-medium) solid;
    float: left;
  }
}

.ql-snow .ql-editor img {
  max-height: 100em;
}

.ql-snow .ql-tooltip {
  margin-left: 200px
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  height: 80%;

  @media only screen and (max-width: 1100px) {
    height: 65%;
  }
}

.ql-toolbar .ql-stroke {
  fill: none;
  stroke: var(--ion-color-primary-contrast);
}

.ql-toolbar .ql-fill {
  fill: var(--ion-color-primary-contrast);
  stroke: none;
}

.ql-toolbar .ql-picker {
  color: var(--ion-color-primary-contrast);
}

.ql-editor.ql-blank::before {
  color: var(--ion-color-primary-contrast)
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background-color: var(--ion-color-primary);
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}