// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {

  /** primary **/
  --ion-color-primary: #001020; //#3880ff;
  --ion-color-primary-rgb: 0, 16, 32;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #000a14;
  --ion-color-primary-tint: #00162d;
  --ion-background-color: var(--ion-color-primary) no-repeat fixed center;
  --app-background: var(--ion-color-primary) no-repeat fixed center;

  /** secondary **/
  --ion-color-secondary: #001B2C;
  --ion-color-secondary-rgb: 0, 27, 44;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #001623;
  --ion-color-secondary-tint: #002135;

  /** tertiary **/
  --ion-color-tertiary: #02253b;
  --ion-color-tertiary-rgb: 2, 37, 59;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #021d2e;
  --ion-color-tertiary-tint: #033453;

  /** quartiary **/
  --ion-color-quartiary: #141b20;
  --ion-color-quartiary-rgb: 20, 27, 32;
  --ion-color-quartiary-contrast: #ffffff;
  --ion-color-quartiary-contrast-rgb: 255, 255, 255;
  --ion-color-quartiary-shade: #0e1316;
  --ion-color-quartiary-tint: #1e2830;

  /** success **/
  --ion-color-success: #68b984;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #f5b521;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #c03e3e;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #3d2f31;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #a4b1b1;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8; // #f4f5f8
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** alert **/
  --ion-color-alert: var(--ion-color-tertiary);
  --ion-color-alert-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-alert-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-alert-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-alert-shade: var(--ion-color-tertiary-shade);
  --ion-color-alert-tint: var(--ion-color-tertiary-tint);

  /** sidemenu **/
  --ion-color-sidemenu: var(--ion-color-tertiary);
  --ion-color-sidemenu-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-sidemenu-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-sidemenu-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-sidemenu-shade: var(--ion-color-tertiary-shade);
  --ion-color-sidemenu-tint: var(--ion-color-tertiary-tint);

  /** searchbar **/
  --ion-color-searchbar: var(--ion-color-tertiary);
  --ion-color-searchbar-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-searchbar-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-searchbar-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-searchbar-shade: var(--ion-color-tertiary-shade);
  --ion-color-searchbar-tint: var(--ion-color-tertiary-tint);

  /** favorite **/
  --ion-color-favorite: #f5b521;
  --ion-color-favorite-rgb: 255, 196, 9;
  --ion-color-favorite-contrast: #000000;
  --ion-color-favorite-contrast-rgb: 0, 0, 0;
  --ion-color-favorite-shade: #e0ac08;
  --ion-color-favorite-tint: #ffca22;

  /** notFavorite **/
  --ion-color-notFavorite: #f4f5f8;
  --ion-color-notFavorite-rgb: 244, 245, 248;
  --ion-color-notFavorite-contrast: #000000;
  --ion-color-notFavorite-contrast-rgb: 0, 0, 0;
  --ion-color-notFavorite-shade: #d7d8da;
  --ion-color-notFavorite-tint: #f5f6f9;

  /** loadingSpinner **/
  --ion-color-loadingSpinner: #e2e2e2; // #f4f5f8
  --ion-color-loadingSpinner-rgb: 244, 245, 248;
  --ion-color-loadingSpinner-contrast: #000000;
  --ion-color-loadingSpinner-contrast-rgb: 0, 0, 0;
  --ion-color-loadingSpinner-shade: #d7d8da;
  --ion-color-loadingSpinner-tint: #f5f6f9;

  /** active **/
  --ion-color-active: var(--ion-color-secondary);
  --ion-color-active-rgb: var(--ion-color-secondary-rgb);
  --ion-color-active-contrast: var(--ion-color-secondary-contrast);
  --ion-color-active-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-active-shade: var(--ion-color-secondary-shade);
  --ion-color-active-tint: var(--ion-color-secondary-tint);

  /** inactive **/
  --ion-color-inactive: var(--ion-color-quartiary);
  --ion-color-inactive-rgb: var(--ion-color-quartiary-rgb);
  --ion-color-inactive-contrast: var(--ion-color-quartiary-contrast);
  --ion-color-inactive-contrast-rgb: var(--ion-color-quartiary-contrast-rgb);
  --ion-color-inactive-shade: var(--ion-color-quartiary-shade);
  --ion-color-inactive-tint: var(--ion-color-quartiary-tint);

  /** adminDetailsViewBg **/
  --ion-color-adminDetailsViewBg: var(--ion-color-secondary);
  --ion-color-adminDetailsViewBg-rgb: var(--ion-color-secondary-rgb);
  --ion-color-adminDetailsViewBg-contrast: var(--ion-color-secondary-contrast);
  --ion-color-adminDetailsViewBg-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-adminDetailsViewBg-shade: var(--ion-color-secondary-shade);
  --ion-color-adminDetailsViewBg-tint: var(--ion-color-secondary-tint);

  /** adminDetailsViewActive **/
  --ion-color-adminDetailsViewActive: var(--ion-color-tertiary);
  --ion-color-adminDetailsViewActive-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-adminDetailsViewActive-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-adminDetailsViewActive-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-adminDetailsViewActive-shade: var(--ion-color-tertiary-shade);
  --ion-color-adminDetailsViewActive-tint: var(--ion-color-tertiary-tint);

  /** adminDetailsViewInactive **/
  --ion-color-adminDetailsViewInactive: var(--ion-color-quartiary);
  --ion-color-adminDetailsViewInactive-rgb: var(--ion-color-quartiary-rgb);
  --ion-color-adminDetailsViewInactive-contrast: var(--ion-color-quartiary-contrast);
  --ion-color-adminDetailsViewInactive-contrast-rgb: var(--ion-color-quartiary-contrast-rgb);
  --ion-color-adminDetailsViewInactive-shade: var(--ion-color-quartiary-shade);
  --ion-color-adminDetailsViewInactive-tint: var(--ion-color-quartiary-tint);

}